import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import { useTranslation } from 'react-i18next';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FontAwesomeIcon, faXmark, faPuzzle, } from 'src/application/icons/fontAwesome/light';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { SubtitleBatchContainer } from 'src/domain/Media/modules/Subtitle/containers/SubtitleBatchContainer';
import { ChaptersBatchContainer } from 'src/domain/Media/modules/Chapters/containers/ChaptersBatchContainer';
import styles from './styles.module.scss';
export var ActiveComponent;
(function (ActiveComponent) {
    ActiveComponent["SUBTITLE"] = "SUBTITLE";
    ActiveComponent["CHAPTERS"] = "CHAPTERS";
})(ActiveComponent || (ActiveComponent = {}));
const VideoContentComponents = {
    [ActiveComponent.SUBTITLE]: SubtitleBatchContainer,
    [ActiveComponent.CHAPTERS]: ChaptersBatchContainer,
};
export const MediaContentContainerModal = NiceModal.create(({ onDismiss, medias, view }) => {
    const modal = useModal();
    const { t } = useTranslation();
    const VideoContentComponent = VideoContentComponents[view];
    return (_jsxs(Drawer, { onCloseDrawer: () => {
            modal.remove();
            onDismiss?.();
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_MODAL_CLOSE, 'button', [
                {
                    key: 'view',
                    value: view,
                },
            ]);
        }, dismissible: false, opened: modal.visible, className: styles.modal, position: 'bottom', children: [_jsx("div", { slot: 'heading', children: _jsxs("header", { "data-testid": 'media-content-container-modal-header', className: styles.heading, children: [_jsxs("div", { className: styles.headerContent, children: [_jsx("span", { className: styles.iconTitleModal, children: _jsx(FontAwesomeIcon, { icon: faPuzzle }) }), _jsx(Heading, { type: 'h4', size: 'small', className: styles.modalTitle, children: t('additional_content.modal.header_title') })] }), _jsx(Button, { variation: 'tertiary', className: styles.closeButton, onClick: () => {
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_MODAL_CLICK_CLOSE_BUTTON, 'button', [
                                    {
                                        key: 'view',
                                        value: view,
                                    },
                                ]);
                                modal.hide();
                            }, children: _jsx(FontAwesomeIcon, { icon: faXmark }) })] }) }), _jsx("div", { slot: 'content' }), _jsx(VideoContentComponent, { medias: medias, onSubmit: () => modal.hide() }), _jsx("div", { slot: 'controls' })] }));
});
