import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { UploadAction } from 'src/application/pages/Library/components/UploadAction';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { TextTruncate } from 'src/ui/TextTruncate';
import { ability } from 'src/infrastructure/libs/CASL';
import styles from './styles.module.scss';
const Header = ({ folderName }) => {
    const { t } = useTranslation();
    const canCreateFolderOrUploadMedia = ability.can('upload', 'Media') || ability.can('edit', 'Media');
    return (_jsxs("div", { className: styles.container, children: [_jsx(Heading, { type: 'h2', children: _jsx(TextTruncate, { children: folderName }) }), canCreateFolderOrUploadMedia && (_jsx(UploadAction, { dropdownButton: {
                    className: styles.dropdownButton,
                    label: t('general.new'),
                } }))] }));
};
export default Header;
