import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BasicPromptModal } from 'src/ui/Modal/components/BasicPromptModal';
import { faPen, FontAwesomeIcon, } from 'src/application/icons/fontAwesome/light';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useMediaRename } from 'src/domain/Media/hooks';
import { useModal } from '@ebay/nice-modal-react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { TextTruncate } from 'src/ui/TextTruncate';
import { Can } from 'src/infrastructure/libs/CASL';
import styles from './styles.module.scss';
const Header = ({ media }) => {
    const { t } = useTranslation();
    const renameMediaModalId = `rename-media-modal-${media.code}`;
    const renameMediaModal = useModal(renameMediaModalId);
    const { renameMedia, isLoading: loadingRenameMedia } = useMediaRename();
    const handleRenameMedia = async () => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_RENAME_MEDIA);
        const newName = (await renameMediaModal.show());
        await renameMedia({ media, name: newName });
        renameMediaModal.hide();
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_RENAME_MEDIA);
    };
    return (_jsxs("header", { className: styles.header, children: [_jsx(Heading, { type: 'h2', className: styles.mediaName, children: _jsx(TextTruncate, { children: media.name }) }), _jsx(Can, { I: 'edit', a: 'Media', children: _jsx(Button, { variation: 'tertiary', onClick: handleRenameMedia, children: _jsx(FontAwesomeIcon, { icon: faPen }) }) }), _jsx(BasicPromptModal, { id: renameMediaModalId, title: t('general.rename'), value: media.name, loading: loadingRenameMedia, buttons: {
                    save: { label: t('general.save') },
                    cancel: { label: t('general.cancel') },
                } })] }));
};
export default Header;
