import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Error from 'src/ui/TransferStateManager/error';
import Optimizing from 'src/ui/TransferStateManager/optimizing';
import Success from 'src/ui/TransferStateManager/success';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useTranslation } from 'react-i18next';
import Progress from 'src/ui/TransferStateManager/progress';
import { ChaptersStatus } from 'src/infrastructure/endpoints/chapters/interfaces';
import { ability } from 'src/infrastructure/libs/CASL';
import { useChaptersBatch } from '../../hooks/useChaptersBatch';
import styles from './styles.module.scss';
const ChaptersItemsGenerated = {
    [ChaptersStatus.COMPLETED]: Success,
    [ChaptersStatus.QUEUED]: Optimizing,
    [ChaptersStatus.FAILED]: Error,
    [ChaptersStatus.PROCESSING]: Progress,
};
const ChaptersBatchItems = () => {
    const { listChapters: { data }, } = useChaptersBatch();
    const { retryFailedChapters } = useChaptersBatch();
    const { t } = useTranslation('', { keyPrefix: 'upload.chapters' });
    return (_jsx(_Fragment, { children: data?.map((item) => {
            const UploadItemStateComponent = ChaptersItemsGenerated[item.status];
            return (_jsx("div", { className: styles.info, "data-testid": 'chapters-batch-items', children: _jsx("div", { className: styles.box, children: UploadItemStateComponent && (_jsx(UploadItemStateComponent, { item: {
                            title: t(`${item.status.toLowerCase().replace('failed', 'error')}.title`, {
                                quantity: item[item.status.toLowerCase()],
                                amount: item.total,
                            }),
                            ...(item.status === ChaptersStatus.FAILED && {
                                errorMessage: t('error.description'),
                                ...(ability.can('edit', 'Media') && {
                                    onClickToRetry: () => {
                                        retryFailedChapters.mutateAsync(item.id);
                                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.CHAPTERS_BATCH_LIST_RETRY);
                                    },
                                }),
                            }),
                        } })) }) }, item.id));
        }) }));
};
export default ChaptersBatchItems;
