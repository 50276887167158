import { jsx as _jsx } from "react/jsx-runtime";
import NiceModal from '@ebay/nice-modal-react';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { MoveModal } from 'src/domain/Folder/containers/Modal/MoveModal';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ability } from 'src/infrastructure/libs/CASL';
import { ListItem } from '../../components/ListItem';
export const ListMediaItem = ({ media, isSelected, toggleSelect, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const pathname = location.pathname;
    const searchedValue = searchParams.get('value');
    const canEditMedia = ability.can('edit', 'Media');
    const shouldShowFileName = (media) => {
        return (pathname === '/search' &&
            media.name !== media.fileName &&
            !!new RegExp(searchedValue, 'i').exec(media.fileName || ''));
    };
    return (_jsx(ListItem, { item: media, type: 'media', selected: isSelected, onSelect: () => toggleSelect(), mediaDetailsDisabled: !canEditMedia, onClick: () => {
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_SETTINGS_MEDIA);
            navigate(`/library/media/${media.code}`, {
                state: {
                    referrer: `${location.pathname}${location.search}`,
                },
            });
        }, onMove: () => {
            NiceModal.show(MoveModal, {
                itemsToMove: [media],
            });
        }, showFileName: shouldShowFileName(media) }));
};
