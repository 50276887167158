import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useCallback, useRef, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useCreateFolder } from 'src/domain/Folder/hooks';
import { INITIAL_FILE_CONFIG_ALLOWED } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/constants';
import { useFileUploadProcess } from 'src/domain/Media/modules/MediaUpload/contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { GeneralStatus } from 'src/domain/Media/modules/MediaUpload/interfaces/FileUploadProps';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import { faFileArrowUp, faUpload, faFolder, FontAwesomeIcon, } from 'src/application/icons/fontAwesome/light';
import { faAdd } from 'src/application/icons/fontAwesome/regular';
import { BasicPromptModal } from 'src/ui/Modal/components/BasicPromptModal';
import { Can } from 'src/infrastructure/libs/CASL';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UploadAndImportModal } from 'src/domain/Media/components/UploadAndImport/UploadAndImportModal';
import { Dropdown, DropdownItem, DropdownHeader, } from 'src/ui/cosmosWeb/DropDown';
import { Button } from 'src/ui/cosmosWeb/Button';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import styles from './styles.module.scss';
export const UploadAction = ({ dropdownButton }) => {
    const { currentAccount } = useAccounts();
    const { isEnabled } = useFeatureToggle({
        accountOwnerMarketplaceId: currentAccount.accountOwnerMarketplaceId,
    });
    const isImportAndUploadEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_MEDIA_IMPORT);
    const { t } = useTranslation();
    const { onSelectFiles, generalUploadState } = useFileUploadProcess();
    const hasPendingFile = generalUploadState === GeneralStatus.UPLOADING;
    const [files, setFiles] = useState('');
    const inputFileRef = useRef(null);
    const { folderCode } = useParams();
    const createFolder = useCreateFolder();
    const handleCreateFolder = useCallback(async () => {
        const name = (await NiceModal.show(BasicPromptModal, {
            loading: createFolder.isLoading,
            title: t('modal.folder.create.title'),
            buttons: {
                save: {
                    label: t('general.create'),
                },
                cancel: {
                    label: t('general.cancel'),
                },
            },
        }));
        await createFolder.mutateAsync({
            name,
            parentFolderCode: folderCode ?? '',
        });
        NiceModal.hide(BasicPromptModal);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_NEW_FOLDER);
    }, [createFolder, folderCode, t]);
    return (_jsxs(Dropdown, { slot: 'actions', docked: true, position: 'end', className: styles.dropdown, children: [_jsxs(Button, { slot: 'controls', "data-testid": 'upload-action-button', className: dropdownButton?.className, size: 'medium', iconPosition: 'end', children: [dropdownButton?.label ?? '', _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faAdd, color: 'white' }) })] }), _jsx(DropdownHeader, { slot: 'items', className: styles.dropdownHeaderItem, children: _jsx("div", { slot: 'label', children: t('library.header.more_options') }) }), _jsx(Can, { I: 'edit', a: 'Media', children: _jsxs(DropdownItem, { slot: 'items', "data-testid": 'create-folder-button', onClick: handleCreateFolder, children: [_jsx("div", { className: styles.icon, slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faFolder }) }), _jsx("div", { slot: 'label', children: t('library.header.new_folder') })] }) }), _jsx(Can, { I: 'upload', a: 'Media', children: isImportAndUploadEnabled ? (_jsxs(DropdownItem, { slot: 'items', onClick: () => NiceModal.show(UploadAndImportModal, { folderCode }), children: [_jsx("div", { slot: 'icon', className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: faUpload }) }), _jsx("div", { slot: 'label', children: t('library.header.media_import') })] })) : (_jsxs(DropdownItem, { slot: 'items', onClick: () => {
                        inputFileRef.current?.click();
                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_NEW_UPLOAD);
                    }, children: [_jsx("div", { slot: 'icon', className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: faFileArrowUp }) }), _jsx("div", { slot: 'label', children: t('library.header.media_upload') }), _jsx("input", { ref: inputFileRef, "data-testid": 'input-file', accept: INITIAL_FILE_CONFIG_ALLOWED.supportedFiles.join(','), type: 'file', multiple: true, disabled: hasPendingFile, value: files, onChange: (e) => {
                                onSelectFiles(e.target.files, folderCode ?? '');
                                setFiles('');
                            } })] })) })] }));
};
