import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from 'src/ui/cosmos/Alert';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Select, SelectGroup, SelectOption } from 'src/ui/cosmos/Select';
import { SelectMultiple, SelectMultipleOption, } from 'src/ui/cosmos/SelectMultiple';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Loading } from 'src/ui/cosmos/Loading';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { useSubtitleBatches } from '../../hooks/useSubtitleBatches';
import { useListSupportedLanguages } from '../../../Shared/hooks/useListSupportedLanguages';
import styles from './styles.module.scss';
// eslint-disable-next-line max-len
import { useShowSubtitleBatchFeedbackModal } from './SubtitleBatchFeedbackModal/hooks/useShowSubtitleBatchFeedbackModal';
const MAX_NUMBER_OF_LANGUAGES = 3;
const NO_TRANSLATION_VALUE = 'no_translation';
export const SubtitleBatchContainer = ({ medias, onSubmit }) => {
    const { t } = useTranslation();
    const { allSupportedLanguages } = useListSupportedLanguages();
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguages, setTargetLanguages] = useState([]);
    const { translateBatchSubtitle } = useSubtitleBatches();
    const feedbackModal = useShowSubtitleBatchFeedbackModal();
    const handleTranslateBatchSubtitle = async () => {
        const mediaCodes = medias.map((media) => media.code);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_LANGUAGES_SUBTITLES, 'button', [
            {
                key: 'sourceLanguage',
                value: sourceLanguage,
            },
            {
                key: 'targetLanguages',
                value: targetLanguages
                    .map((targetLanguage) => targetLanguage)
                    .join(', '),
            },
        ]);
        try {
            await translateBatchSubtitle.mutateAsync({
                targetLanguages,
                mediaCodes,
                sourceLanguage,
            });
            feedbackModal.showSuccessMessage();
        }
        catch {
            feedbackModal.showErrorMessage(handleTranslateBatchSubtitle);
        }
        finally {
            onSubmit();
        }
    };
    const handleTargetLanguagesChange = (options) => {
        if (options.some((opt) => opt.value === NO_TRANSLATION_VALUE)) {
            setTargetLanguages([sourceLanguage]);
        }
        else {
            setTargetLanguages(options
                .map((opt) => opt.value)
                .filter((value) => value !== NO_TRANSLATION_VALUE)
                .slice(0, MAX_NUMBER_OF_LANGUAGES));
        }
    };
    if (allSupportedLanguages.isLoading) {
        return _jsx(Loading, { full: true });
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { slot: 'content', children: _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.contentHeader, children: [_jsx(Heading, { type: 'h2', size: 'small', className: styles.modalContentTitle, children: t('additional_content.modal.subtitle.header_title') }), _jsx("p", { className: styles.contentDescription, children: t('additional_content.modal.subtitle.content_title') })] }), _jsx("div", { children: _jsx(Alert, { className: `hot-alert--info ${styles.alert}`, children: t('additional_content.modal.subtitle.alert') }) }), _jsxs("div", { className: styles.subtitleContainer, children: [_jsxs("div", { className: styles.inputContainer, children: [_jsx("p", { className: styles.inputLabel, children: t('additional_content.modal.subtitle.form.label_source_language') }), _jsx(Select, { className: styles.select, onChange: (event) => setSourceLanguage(event.detail.value), placeholder: t('additional_content.modal.subtitle.form.label_source_language'), children: _jsx(SelectGroup, { children: allSupportedLanguages?.data?.map((language) => (_jsx(SelectOption, { value: language, children: t(`subtitle.supported_languages.${language}`) }, language))) }) })] }), _jsxs("div", { className: styles.inputContainer, children: [_jsx("p", { className: styles.inputLabel, children: t('additional_content.modal.subtitle.form.label_target_languages') }), _jsx(SelectMultiple, { disableSelectAll: true, className: styles.select, disabled: sourceLanguage === '', onChange: handleTargetLanguagesChange, placeholder: t('additional_content.modal.subtitle.form.label_target_languages'), children: _jsxs(SelectGroup, { children: [_jsx(SelectMultipleOption, { value: NO_TRANSLATION_VALUE, children: t('additional_content.modal.subtitle.no_translate') }), allSupportedLanguages?.data
                                                        ?.filter((language) => language !== sourceLanguage)
                                                        .map((language) => (_jsx(SelectMultipleOption, { value: language, disabled: targetLanguages.includes(sourceLanguage) ||
                                                            (targetLanguages.length >= MAX_NUMBER_OF_LANGUAGES &&
                                                                !targetLanguages.includes(language)), children: t(`subtitle.supported_languages.${language}`) }, language)))] }) })] })] })] }) }), _jsx("div", { slot: 'controls', className: styles.controls, children: _jsx(Button, { variation: 'primary', disabled: targetLanguages.length === 0 || sourceLanguage === '', onClick: () => {
                        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_CLICK_GENERATE_SUBTITLES);
                        handleTranslateBatchSubtitle();
                    }, "data-testid": 'button-generate-batch-subtitle', children: t('additional_content.modal.subtitle.button_generate_subtitle') }) })] }));
};
